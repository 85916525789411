import React from 'react';
import './i18n';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTopRoute from './components/ScrollTopRoute';
import FlowPopup from './components/FlowPopup';
import About from './pages/About';
import Contact from './pages/Contact';
import Faq from './pages/FaqPage';
import Home1 from './pages/Home1';
import Products from './pages/Products';
import ProductsDetails from './pages/ProductsDetails';
import NotFound from "./pages/NotFound";

function App() {
  return (
    <>
      <Router>
        <ScrollToTopRoute />
        <Routes>
          <Route exact path="/about" element={<About />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/machines" element={<Products machineCategory={"all"} />} />
          <Route exact path="/machines/silicone" element={<Products machineCategory={"silicone"} />} />
          <Route exact path="/machines/rubber" element={<Products machineCategory={"rubber"} />} />
          <Route exact path="/machines/brake" element={<Products machineCategory={"brake"} />} />
          <Route exact path="/machines/customizations" element={<Products machineCategory={"custom"} />} />
          <Route exact path="/machines/auxiliary" element={<Products machineCategory={"auxiliary"} />} />

          <Route exact path="/machine/brake-pad-and-lining-hot-press-machine" element={<ProductsDetails productName={'brakeHP'} />} />
          <Route exact path="/machine/hydraulic-hot-press-machine" element={<ProductsDetails productName={'HF'} />} />
          <Route exact path="/machine/horizontal-rubber-injection-molding-machine/hi-fl" element={<ProductsDetails productName={'HIFLRubber'} />} />
          <Route exact path="/machine/horizontal-silicone-injection-molding-machine/hi-fl" element={<ProductsDetails productName={'HIFLSilicone'} />} />
          <Route exact path="/machine/vertical-liquid-silicone-injection-molding-machine" element={<ProductsDetails productName={'LV1'} />} />
          <Route exact path="/machine/solid-liquid-silicone-molding-machine" element={<ProductsDetails productName={'LV2'} />} />
          <Route exact path="/machine/vertical-transfer-press-molding-machine" element={<ProductsDetails productName={'TPPress'} />} />
          <Route exact path="/machine/vacuum-compression-molding-machine" element={<ProductsDetails productName={'VF'} />} />
          <Route exact path="/machine/vertical-rubber-injection-molding-machine/vi-ao" element={<ProductsDetails productName={'VIAORubber'} />} />
          <Route exact path="/machine/vertical-solid-silicone-injection-molding-machine/vi-ao" element={<ProductsDetails productName={'VIAOSilicone'} />} />
          <Route exact path="/machine/vertical-rubber-injection-molding-machine/vi-fl" element={<ProductsDetails productName={'VIFL'} />} />
          <Route exact path="/machine/vertical-rubber-injection-molding-machine/vi-fo" element={<ProductsDetails productName={'VIFO'} />} />

          <Route exact path="/machine/rubber-two-roll-mill-machine" element={<ProductsDetails productName={'TwoRollMill'} />} />
          <Route exact path="/machine/antistatic-washing-drying-machine" element={<ProductsDetails productName={'WashingDrying'} />} />
          <Route exact path="/machine/stainless-steel-hot-air-rotary-drum-dryer" element={<ProductsDetails productName={'HotDry'} />} />
          <Route exact path="/machine/rubber-deflashing-machine" element={<ProductsDetails productName={'Deflashing'} />} />
          <Route exact path="/machine/silicone-strip-cutting-machine" element={<ProductsDetails productName={'SiliconeCutting'} />} />
          <Route exact path="/machine/rubber-strip-cutting-machine" element={<ProductsDetails productName={'RubberCutting'} />} />
          <Route exact path="/machine/two-roll-open-mill-machine-for-rubber-mixing" element={<ProductsDetails productName={'OpenMill'} />} />
          <Route exact path="/machine/air-separation-machine-with-automatic-feeder" element={<ProductsDetails productName={'Feeder'} />} />
          <Route exact path="/machine/punching-machine" element={<ProductsDetails productName={'Punching'} />} />

          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/" element={<Home1 />} />
          <Route path="*" element={<NotFound />} status={404} />
        </Routes>
      </Router>
      <FlowPopup />
    </>
  );
}

export default App;