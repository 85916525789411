import React from 'react';
import { CgChevronDown, CgChevronLeft } from 'react-icons/cg';

const menuData = [
    {
        title: 'Home',
        path: '/',
        translateKey: 'Home'
    },
    {
        title: 'Machines',
        path: '/machines',
        translateKey: 'MachinesMenu',
        iconClosed: <CgChevronLeft />,
        iconOpened: <CgChevronDown />,
        subNav: [
            {
                title: 'Rubber Molding Machines',
                path: '/machines/rubber',
                cName: 'rubber',
                translateKey: 'Rubber Molding Machines'
            },
            {
                title: 'Silicone Molding Machines',
                path: '/machines/silicone',
                cName: 'silicone',
                translateKey: 'Silicone Molding Machines'
            },
            {
                title: 'Brake Manufacturing Machines',
                path: '/machines/brake',
                cName: 'brake',
                translateKey: 'Brake Manufacturing Machines'
            },
            {
                title: 'Auxiliary Machines',
                path: '/machines/auxiliary',
                cName: 'auxiliary',
                translateKey: 'Auxiliary Machines'
            },
            {
                title: 'Customized Machines',
                path: '/machines/customizations',
                cName: 'custom',
                translateKey: 'Customized Machines'
            },
            {
                title: 'All Machines',
                path: '/machines',
                cName: 'all',
                translateKey: 'All Machines'
            },
        ],
    },
    {
        title: 'About Us',
        path: '/about',
        translateKey: 'About Us'
    },
    // {
    //     title: 'Projects',
    //     path: '/projects',
    // },
    {
        title: 'Faq',
        path: '/faq',
        translateKey: 'faq'
    },
    {
        title: 'Contact',
        path: '/contact',
        translateKey: 'Contact'
    },
];

export default menuData;