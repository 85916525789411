import React from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import {
    FaClock,
    FaWhatsapp,
    FaMap,
    FaPlus,
    FaYoutube,
    FaTiktok
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/logo.png';
import { useTranslation } from 'react-i18next';

function OffsetMenu({ className, handleOffset }) {
    const { t } = useTranslation();

    return (
        <div className={className}>
            <span id="offset-menu-close-btn">
                <FaPlus onClick={handleOffset} />
            </span>
            <div className="offset-menu-wrapper text-white">
                <div className="offset-menu-header">
                    <div className="offset-menu-logo">
                        <Link to="/">
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>
                </div>
                <div className="offset-menu-section">
                    <h3>{t('About Us')}</h3>
                    <p>{t('AboutUsDescription')}</p>
                    <Link to="/contact" className="theme-btn mt-30">
                        {t('Contact')}
                    </Link>
                </div>
                <div className="offset-menu-section">
                    <h3>{t('ContactInfo')}</h3>
                    <ul>
                        <li>
                            <span>
                                <FaMap />
                            </span>
                            {t('Address')}
                        </li>
                        <li>
                            <span>
                                <a href="https://wa.me/17373281987">
                                    <FaWhatsapp />
                                </a>
                            </span>
                            {t('PhoneNumber')}
                        </li>
                        <li>
                            <span>
                                <a href="mailto:info@juchuanmachines.com">
                                    <AiOutlineMail className="icon" />
                                </a>
                            </span>
                            {t('Email')}
                        </li>
                        <li>
                            <span>
                                <FaClock />
                            </span>
                            {t('workingHoursValue')}
                        </li>
                    </ul>
                </div>
                <div className="offset-menu-footer">
                    <div className="offset-menu-social-icon">
                        <a href="https://wa.me/17373281987">
                            <FaWhatsapp />
                        </a>
                        <a href="https://www.tiktok.com/@juchuanmachines">
                            <FaTiktok />
                        </a>
                        <a href="https://www.youtube.com/@JuchuanManchinery">
                            <FaYoutube />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OffsetMenu;