import React from 'react';
import bannerBg from '../assets/img/home/juchuan-page-banner-web.jpeg';
import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';
import PageBanner from '../components/PageBanner';
import Process from '../components/Process';
import ProductsGrid from '../components/ProductsGrid';
import Technologies from '../components/Technologies';
import ProductionProcess from '../components/ProductionProcess';

function Products({ machineCategory }) {
    return (
        <>
            <Header1 />
            <PageBanner bannerBg={bannerBg} currentPage="Machines" heading="Our Machines" />
            <ProductsGrid machineCategory={machineCategory} />
            <ProductionProcess />
            <Process />
            <Technologies />
            <Footer1 />
        </>
    );
}

export default Products;
