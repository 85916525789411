import React from 'react';
import { useTranslation } from 'react-i18next';

function Topbar() {
    const { t, i18n } = useTranslation();
    
    const changeLanguage = (event) => {
        const newLang = event.target.value;
        i18n.changeLanguage(newLang);
        localStorage.setItem('userLanguage', newLang);
    };
    
    return (
        <div className="top-bar">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-sm-8 d-none d-sm-block">
                        <p className="top-left mb-0">
                            {t('workingHours')}: <span>{t('workingHoursValue')}</span>
                        </p>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="lan-select text-sm-right">
                            <form>
                                <select id="lan" onChange={changeLanguage} value={i18n.language}>
                                    <option value="en">English</option>
                                    {/* <option value="zh">中文</option> */}
                                </select>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Topbar;