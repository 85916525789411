import { v4 as uuidv4 } from 'uuid';
import thumb1 from '../../assets/img/home/board-web.jpg';
import thumb2 from '../../assets/img/home/close-web.jpg';
import thumb3 from '../../assets/img/home/innovate-web.jpg';

const corporateCultureData = [
    {
        id: uuidv4(),
        thumb: thumb1,
        heading: 'Our Management Philosophy',
        text: 'Prioritize quality as our foundation while creating value for our users.',
    },

    {
        id: uuidv4(),
        thumb: thumb2,
        heading: 'Our Mission',
        text: 'Establish ourselves as a first-class innovative enterprise',
    },
    
    {
        id: uuidv4(),
        thumb: thumb3,
        heading: 'Our Values',
        text: 'Committed to a people-oriented approach and technological innovation, focusing on creating first-class products and delivering satisfactory services.',
    },
];

export default corporateCultureData;
