import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import HeroBg1 from '../../assets/img/home/juchuan-hero1-web.jpeg';
import { useTranslation } from 'react-i18next';

function Hero1() {
    const { t } = useTranslation();

    return (
        <section className="hero-slide-wrapper hero-1">
            <Swiper className="hero-slider-active owl-carousel">
                <SwiperSlide>
                    <div
                        className="single-slide bg-cover"
                        style={{ backgroundImage: `url(${HeroBg1})` }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-8 col-xl-6">
                                    <div className="hero-contents">
                                        <h1>{t('Heading')}</h1>
                                        <Link to="/machines" className="theme-btn">
                                            {t('Machines')}
                                            <BsArrowRight style={{ marginLeft: '10px' }} />
                                        </Link>
                                        <Link to="/about" className="theme-btn black">
                                            {t('Learn more')}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
    );
}

export default Hero1;
