import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import image1  from '../../assets/img/factoryImages/juchuan-factory-web.jpg';
import image2  from '../../assets/img/factoryImages/juchuan-factory-side2-web.jpg';
import image3  from '../../assets/img/factoryImages/juchuan-factory-side-web.jpg';
import image4  from '../../assets/img/factoryImages/juchuan-at-work-web.jpg';
import image5  from '../../assets/img/factoryImages/juchuan-rubber-machine-vacuum-web.jpg';
import image6  from '../../assets/img/factoryImages/juchuan-old-logo-web.jpg';
import image7  from '../../assets/img/factoryImages/juchuan-rubber-machine-web.jpg';
import image8  from '../../assets/img/factoryImages/juchuan-board-web.jpg';
import image9  from '../../assets/img/factoryImages/juchuan_detail-web.jpg';

function FactoryGallery() {
    const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9];

    return (
        <>
            <section className="about-featured-wrapper mb-60">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-4 col-12">
                            <div className="gallery-contents ml-lg-5">
                                <h4>
                                Juchuan offers advanced <strong>silicone and rubber processing equipment</strong>, including precision injection molding machines, vacuum vulcanizing systems, and custom molds. Our engineers collaborate with German experts to develop patented technologies, ensuring innovative solutions in precision molding and injection.
                                </h4>
                            </div>
                        </div>
                        
                        <div className="col-xl-8 col-12">
                            <Swiper
                                navigation
                                pagination={{ clickable: true }}
                                slidesPerView={1}
                                spaceBetween={30}
                                loop={true}
                                watchSlidesProgress={true}
                                loopadditionalslides={3}
                                speed={800}
                                observer={true}
                                observeParents={true}
                            >
                                {images.map((image, index) => (
                                    <SwiperSlide key={index}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '500px'
                                        }}>
                                            <img
                                                src={image}
                                                alt={`Juchuan Machinery Factory ${index + 1}`}
                                                style={{
                                                    maxWidth: '100%',
                                                    maxHeight: '100%',
                                                    objectFit: 'contain'
                                                }}
                                                loading="lazy"
                                            />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FactoryGallery;