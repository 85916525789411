import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslations from './locales/en/translation.json';
import zhTranslations from './locales/zh/translation.json';

const resources = {
  en: { translation: enTranslations },
  zh: { translation: zhTranslations }
};

const userLanguage = localStorage.getItem('userLanguage') || 'en';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    supportedLngs: ['en', 'zh'],
    lng: userLanguage,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
