import React, { useEffect } from 'react';
import bannerBg from '../assets/img/home/juchuan-page-banner-web.jpeg';
import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';
import PageBanner from '../components/PageBanner';
import { useNavigate, useLocation } from 'react-router-dom'

function NotFound() {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (typeof window.__REACT_ROUTER_LOCATION__ !== 'undefined') {
            window.__REACT_ROUTER_LOCATION__.status = 404
        }
        const redirectTimer = setTimeout(() => {
            navigate('/')
        }, 5000)

        return () => clearTimeout(redirectTimer)
    }, [navigate, location])

    return (
        <>
            <Header1 />
            <PageBanner bannerBg={bannerBg} heading="Page Not Found" currentPage="Not Found" />

            <section className="contact-page-wrap section-padding">
                <div className="container">

                    <div className="row">
                        <h1>404 Page not found</h1>
                    </div>
                    <br />
                    <div className="row">
                        <p>We can't find the page you are looking for.</p>
                        <p>You will be redirected back to the home page in 5 seconds.</p>
                    </div>

                </div>
            </section>

            <Footer1 />
        </>
    );
}
export default NotFound