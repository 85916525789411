import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ServicesOneCard({ bgImg, icon, heading, btnText, catLink }) {
    const { t } = useTranslation();

    // Map the heading to the corresponding translation key based on the original data
    const getTranslationKey = (heading) => {
        switch (heading) {
            case 'Rubber Molding Machines':
                return 'Rubber Molding Machines';
            case 'Silicone Molding Machines':
                return 'Silicone Molding Machines';
            case 'Brake Production Machines':
                return 'Brake Manufacturing Machines';
            case 'Customized Machines':
                return 'Customized Machines';
            default:
                return heading;
        }
    };

    return (
        <div className="col-md-6 col-xl-3 col-12">
            <div className="single-service-item service-1">
                <div
                    className="service-bg bg-cover"
                    style={{
                        backgroundImage: `url(${bgImg})`,
                    }}
                />
                <div
                    className="icon"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px',
                    }}
                >
                    <img src={icon} alt="icon" style={{ maxHeight: '100%' }} />
                </div>
                <h3>{t(getTranslationKey(heading))}</h3>
                <Link to={catLink}>
                    <span>{t('Learn more')}</span>
                    <BsArrowRight style={{ fontSize: '18px' }} />
                </Link>
            </div>
        </div>
    );
}

export default ServicesOneCard;