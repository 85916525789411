import React, { useState } from 'react';
import Select from 'react-select';
import FormInput from './FormInput';
import countryOptions from '../SharedFormData/CountriesOptions';
import machineOptions from '../SharedFormData/MachineOptions';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

function ContactFormContent({ title, heading }) {
    const [isLoading, setIsLoading] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState(null);
    const [formData, setFormData] = useState({
        company: '',
        fullname: '',
        application: '',
        email: '',
        message: '',
        machine: machineOptions[0],
        country: '',
    });

    const onChangeHandler = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    };

    const onSelectChange = (selectedOption, { name }) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: selectedOption
        }));
    };

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        
        const url = 'https://form-automation.juchuanofficial.workers.dev';
        var status
        var error_detail
    
        const requestBody = {
            company: formData.company,
            name: formData.fullname,
            email: formData.email,
            application: formData.application,
            message: formData.message,
            machine: formData.machine.label,
            country: formData.country.label
        };
        console.log(requestBody);
    
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
    
            if (response.ok) {
                setSubmissionStatus('success');
                status = "success"
                error_detail = ''
            } else {
                setSubmissionStatus('error');
                status = "error"
                error_detail = response.status
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setSubmissionStatus('error');
            status = "exception"
            error_detail = error
        } finally {
            setIsLoading(false);
            window.gtag_report_conversion(1.0, status, 'contact_form', error_detail)
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-12 text-center mb-20">
                    <span>{title}</span>
                    <h1>{heading}</h1>
                    {submissionStatus === 'error' && (
                        <p style={{ marginTop: '15px', color: 'red' }}>Error submitting form: <br/> We apologize for the inconvenience. We're currently experiencing technical difficulties. Please email us at: info@juchuanmachines.com or try again later.</p>
                    )}
                </div>

                {submissionStatus === 'success' ? (
                    <div className="col-12 text-center">
                        <h3>Thank you for your submission <MarkEmailReadIcon /></h3>
                        <p>We have received your form and will get back to you soon!</p>
                    </div>
                ) : (
                <div className="col-12 col-lg-12">
                    <div className="contact-form">
                        <form onSubmit={onSubmitHandler} className="row conact-form">
                            <FormInput
                                type="text"
                                labelFor="company"
                                label="Company Name"
                                placeholder="Enter Company Name"
                                id="company"
                                value={formData.company}
                                onChange={onChangeHandler}
                                autoComplete="company"
                                required
                            />
                            <FormInput
                                type="text"
                                labelFor="fullname"
                                label="Full Name"
                                placeholder="Enter Name"
                                id="fullname"
                                value={formData.fullname}
                                onChange={onChangeHandler}
                                autoComplete="name"
                                required
                            />
                            <FormInput
                                type="email"
                                labelFor="email"
                                label="Email Address"
                                placeholder="Enter Email Address"
                                id="email"
                                value={formData.email}
                                onChange={onChangeHandler}
                                autoComplete="email"
                                required
                            />
                            <FormInput
                                type="text"
                                labelFor="application"
                                label="Product Application"
                                placeholder="What products does your company make?"
                                id="application"
                                value={formData.application}
                                onChange={onChangeHandler}
                                autoComplete="off"
                                required
                            />
                            <div className="col-md-6 col-12">
                                <div className="single-personal-info">
                                    <label htmlFor="machine-select">Machine of Interest</label>
                                    <Select
                                        inputId="machine-select"
                                        name="machine"
                                        options={machineOptions}
                                        value={formData.machine || null}
                                        onChange={(selectedOption, actionMeta) => onSelectChange(selectedOption, actionMeta)}
                                        placeholder="Select a machine"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="single-personal-info">
                                    <label htmlFor="country-select">Country</label>
                                    <Select
                                        inputId="country-select"
                                        name="country"
                                        options={countryOptions}
                                        value={formData.country || null}
                                        onChange={(selectedOption, actionMeta) => onSelectChange(selectedOption, actionMeta)}
                                        placeholder="Select a country"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 col-12">
                                <div className="single-personal-info">
                                    <label htmlFor="message">Enter Message</label>
                                    <textarea
                                        value={formData.message}
                                        onChange={onChangeHandler}
                                        id="message"
                                        placeholder="Describe your product for our rubber/silicone molding machine. Include specs and preferred contact method."
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 col-12 text-center">
                                <input
                                    className="submit-btn"
                                    type="submit"
                                    value={isLoading ? "Submitting..." : "Get A Quote"}
                                    disabled={isLoading}
                                    style={{
                                        opacity: isLoading ? 0.5 : 1,
                                        cursor: isLoading ? 'not-allowed' : 'pointer'
                                    }}
                                />
                            </div>
                        </form>
                    </div>
                </div>
                )}
            </div>
        </>
    );
}

export default ContactFormContent;