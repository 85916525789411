import React from 'react';
import BgImg from '../../assets/img/subscribe_bg.jpg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Cta() {
    const { t } = useTranslation();
    return (
        <section className="cta-wrapper">
            <div className="container">
                <Link to="/machines/customizations">
                    <div
                        className="cta-content bg-cover"
                        style={{
                            backgroundImage: `url(${BgImg})`,
                        }}
                    >
                        <div className="row align-items-center">
                            <h1 className="cta-heading">{t('cta')}</h1>
                        </div>
                    </div>
                </Link>
            </div>
        </section>
    );
}

export default Cta;
