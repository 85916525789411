import React from 'react';
import bgImg from '../../assets/img/global-clients.png';
import { useTranslation } from 'react-i18next';

function GlobalPresence() {
    const { t } = useTranslation();
    return (
        <section className="global-presence-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="content-block">
                        <p>{t('presense sub title')}</p>
                        <h1>{t('presense title')}</h1>
                        <div className="bg-img">
                            <img src={bgImg} alt="Juchuan Machine Customer Worldwide" loading="lazy"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default GlobalPresence;
