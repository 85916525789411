import React, { useEffect, useState } from 'react';
import bannerBg from '../assets/img/home/juchuan-page-banner-web.jpeg';
import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';
import PageBanner from '../components/PageBanner';
import EachProductDetails from '../components/EachProductDetails';
import FaqAccordion from '../components/FaqAccordion';
import ProductionProcess from '../components/ProductionProcess';

function ProductsDetails({ productName }) {
    const [jsonData, setJsonData] = useState(null);
    const [jsData, setJsData] = useState(null);

    useEffect(() => {
        const loadProductData = async () => {
            try {
                const module = await import(`../machines/machineJson/en/${productName}.json`);
                setJsonData(module.default);
            } catch (error) {
                console.error("Failed to load product data:", error);
                setJsonData(null);
            }
        };

        loadProductData();
    }, [productName]);

    useEffect(() => {
        const loadJsData = async () => {
            try {
                const module = await import(`../machines/machineData/${productName}`);
                setJsData(module.default);
            } catch (error) {
                console.error("Failed to load JS data:", error);
                setJsData(null);
            }
        };

        loadJsData();
    }, [productName]);

    if (!jsonData || !jsData) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Header1 />
            <PageBanner
                bannerBg={bannerBg}
                heading="Product Details"
                currentPage="Product Details"
            />
            <section className="product-details-wrapper section-padding">
                <div className="container">
                    <div className="row">
                        <EachProductDetails jsonData={jsonData} jsData={jsData}/>

                        <ProductionProcess />
                        
                        {/* Questions */}
                        <div className="faq-content pl-0 pl-md-4 pt-10">
                            <h2>Other Questions?</h2>
                            <FaqAccordion
                                question="Can I customize your rubber and silicone molding machines to suit my specific production needs?"
                                answer="Absolutely. We offer customization options for all our rubber and silicone molding equipment. Please fill in the inquiry form on this page with your specific requirements, and our team will provide you with detailed information on how we can tailor our machines to meet your unique production needs."
                            />
                            <FaqAccordion
                                question="How can I determine if your molding machine is suitable for my rubber or silicone product?"
                                answer="To ensure you select the ideal machine for your rubber or silicone product, we recommend completing the inquiry form on this page. Provide a detailed description of your product and production goals, and our experienced team will promptly offer personalized recommendations based on your specific needs."
                            />
                            <FaqAccordion
                                question="I'm uncertain about the specifications I need for my rubber or silicone molding machine. How can you assist me?"
                                answer="We understand that choosing the right specifications can be challenging. For expert guidance, please fill out the inquiry form on this page. Describe your production requirements and any questions you have about specifications. Our team will review your information and provide comprehensive suggestions tailored to your rubber or silicone molding needs as quickly as possible."
                            />
                        </div>
                    </div>
                </div>
            </section>
            <Footer1 />
        </>
    );
}

export default ProductsDetails;
