import React from 'react';
import { FaTh } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function MainMenu({ hanldeOffset }) {
    const { t } = useTranslation();

    return (
        <>
            <div className="main-menu-area">
                <div className="row align-center">
                    <div className="col-6 col-md-8 col-lg-10">
                        <div className="main-menu">
                            <ul>
                                <li>
                                    <Link to="/">{t('Home')}</Link>
                                </li>
                                <li>
                                    <Link to="/machines">{t('MachinesMenu')}</Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/machines/rubber">{t('Rubber Molding Machines')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/machines/silicone">{t('Silicone Molding Machines')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/machines/brake">{t('Brake Manufacturing Machines')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/machines/auxiliary">{t('Auxiliary Machines')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/machines/customizations">{t('Customized Machines')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/machines">{t('All Machines')}</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/about">{t('About Us')}</Link>
                                </li>
                                {/* <li>
                                    <Link to="/projects">Projects</Link>
                                </li> */}
                                <li>
                                    <Link to="/faq">{t('faq')}</Link>
                                </li>
                                <li>
                                    <Link to="/contact">{t('Contact')}</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-6 col-md-8 col-lg-2 icon-wrapper">
                        <button type="button" onClick={hanldeOffset} className="side-menu-toggle">
                            <FaTh />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MainMenu;
