import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AgeImg from '../../assets/img/age.png';

function Promo() {
    const { t } = useTranslation();

    return (
        <section className="promo-featured-wrapper section-padding">
            <div className="container">
                <div className="row align-center">
                    <div className="col-xl-6 col-12 text-center">
                        <img src={AgeImg} alt={t('Heading')} />
                        <h4>
                            {t('Years Of Experience With')} <b>{t('Innovative Manufacturing')}</b>
                        </h4>
                    </div>
                    <div className="col-xl-6 col-12">
                        <div className="block-contents ml-xl-5 mt-5 mt-xl-0">
                            <span>{t('Engineering Excellence')}</span>
                            <h1>{t('Your Trusted Machinery Partner')}</h1>
                            <h4>
                                {t('Crafting Innovative Solutions with Decades of Precision Engineering')}
                            </h4>
                            <p>
                                {t('AboutUsDescription')}
                            </p>
                            <Link to="/contact" className="theme-btn">
                                {t('Contact Us Now')}
                                <BsArrowRight style={{ fontSize: '20px', marginLeft: '15px' }} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Promo;