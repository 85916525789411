import { v4 as uuidv4 } from 'uuid';
import Bg1 from '../../assets/img/home/board-web.jpg';
import Bg3 from '../../assets/img/home/close-web.jpg';
import Bg4 from '../../assets/img/home/back2-web.jpg';
import Bg2 from '../../assets/img/home/back1-web.jpg';
import Icon1 from '../../assets/img/icon/rubber.png';
import Icon2 from '../../assets/img/icon/silicone.png';
import Icon3 from '../../assets/img/icon/brake.png';
import Icon4 from '../../assets/img/icon/custom.png';

const servicesOneData = [
    {
        id: uuidv4(),
        bgImg: Bg1,
        icon: Icon1,
        heading: 'Rubber Molding Machines',
        btnText: 'Learn More',
        catLink: '/machines/rubber'
    },

    {
        id: uuidv4(),
        bgImg: Bg2,
        icon: Icon2,
        heading: 'Silicone Molding Machines',
        btnText: 'Learn More',
        catLink: '/machines/silicone'
    },

    {
        id: uuidv4(),
        bgImg: Bg3,
        icon: Icon3,
        heading: 'Brake Manufacturing Machines',
        btnText: 'Learn More',
        catLink: '/machines/brake'
    },

    {
        id: uuidv4(),
        bgImg: Bg4,
        icon: Icon4,
        heading: 'Customized Machines',
        btnText: 'Learn More',
        catLink: '/machines/customizations'
    },
];

export default servicesOneData;
